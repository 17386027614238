
<template>
  <div class="template_chooser">
    <input type="hidden" name="article[article_template_id]" :value="selectedTemplateId" />
    <div class='template_chooser__templates'>

      <div class='template_chooser__filter'>
        <a href='#' :class='{"selected": setFilter == "abc_asc"}' @click.prevent.stop='changeSetFilter("abc_asc")'>ABC</a>
        <a href='#' :class='{"selected": setFilter == "pop"}' @click.prevent.stop='changeSetFilter("pop")'>Most Popular</a>
      </div>
      <template v-if='setFilter == "pop"'>
        <a href='#' :class='{"template_chooser__template": true, "selected": selectedTemplateId == template.id}' @click.prevent.stop='chooseTemplate(template.id, index )' v-for='(template,index) in pop_article_templates'>
          <h1>{{template.name}}</h1>
        </a>
      </template>
      <template v-if='setFilter == "abc_asc"'>
        <a href='#' :class='{"template_chooser__template": true, "selected": selectedTemplateId == template.id}' @click.prevent.stop='chooseTemplate(template.id, index)' v-for='(template, index) in asc_article_templates'>
          <h1>{{template.name}}</h1>
        </a>
      </template>
    </div>
    <div class='template_chooser__content' v-if='setFilter == "abc_asc"'>
      <h1 class='template_chooser__content__title'>{{asc_article_templates[this.selectedTemplateIndex].name}}</h1>
      <div v-html='asc_article_templates[this.selectedTemplateIndex].content'></div>
    </div>
    <div class='template_chooser__content' v-if='setFilter == "pop"'>
      <h1 class='template_chooser__content__title'>{{pop_article_templates[this.selectedTemplateIndex].name}}</h1>
      <div v-html='pop_article_templates[this.selectedTemplateIndex].content'></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["asc_article_templates", "pop_article_templates"],
  data () {
    return {
      name: "",
      isShown: false,
      loading: false,
      selectedTemplateId: 0,
      selectedTemplateIndex: 0,
      setFilter: 'abc_asc'
    }
  },
  mounted ()  {
    console.log(this.asc_article_templates)
    if (this.asc_article_templates[0]) {
      this.name = this.asc_article_templates[0].name
      this.selectedTemplateId = this.asc_article_templates[0].id
    }
  },
  methods: {
    save () {
      this.loading = true

      var that = this;

      that.$api.post(that.url,  { name: that.name, article_template_id: that.selectedTemplateId}).then(function (response) {

        setTimeout(function () {
          that.loading = false;
          window.location = response.data.url;
        }, 1000);

      }).catch(error => {
        that.loading = false;
      })
    },
    changeSetFilter(value) {
      this.setFilter = value;
      this.selectedTemplateIndex = 0
      if (this.setFilter == "abc_asc") {
        this.selectedTemplateId = this.asc_article_templates[0].id
      } else {
        this.selectedTemplateId = this.pop_article_templates[0].id
      }
    },
    chooseTemplate(id, index) {
      this.selectedTemplateId = id
      this.selectedTemplateIndex = index
      this.name = this.asc_article_templates[index].name
    },
    toggleShow () {
      this.isShown = !this.isShown;
      this.$nextTick(() => document.querySelector("#article_name").focus())
    },
  },

}
</script>
