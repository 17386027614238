import { Controller } from "@hotwired/stimulus"
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";
import flash from "../lib/flashes.js";

export default class extends Controller {
  static values = { url: String, text: String, isOtp: Boolean }

  copy (event) {
    event.preventDefault()

    const that = this;
    const url = that.urlValue;
    const text = that.textValue;
    const isOtp = that.isOtpValue;

    if (text) {
      ClipboardFunctions.copyText(text)
    } else {
      fetch(url)
        .then((resp) => resp.json())
        .then(data => {
          if (isOtp) {
            ClipboardFunctions.copyText(data.otp)
            flash("OTP copied. " + data.time_left + " seconds left", "success")
            return
          } else {
            ClipboardFunctions.copyText(data.password)
          }
        })
        .catch(function(error) {
          console.log(error)
        });
    }

    if (!isOtp) {
      flash("Copied to clipboard", "success")
    }

  }
}
