

require("@rails/ujs").start()
import * as Turbo from "@hotwired/turbo"
import "@hotwired/turbo-rails"
import "./lib/choices_lib.js"
import "./controllers"
import tippy from 'tippy.js';
//Turbo.session.drive = false

import Prism from"./lib/prism.js";
import '@material/mwc-circular-progress';

import TomSelect from 'tom-select';

function mountGlobalScripts () {
  tippy('[data-tippy-content]');
  window.Prism = window.Prism || {};
  window.Prism.manual = true;
  Prism.highlightAll();
  if (document.querySelector(".js-choice")) {
    new TomSelect(".js-choice",{
      create: true,
      sortField: {
        field: "text",
        direction: "asc"
      }
    });
  }
}

document.addEventListener('turbo:load', () => {
  mountGlobalScripts();
})

document.addEventListener('DOMContentLoaded', () => {
  mountGlobalScripts();
})

