import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import axios from "axios"

export default class extends CheckboxSelectAll {
  static targets = ["actions", "count", "action", "idInput", "container", "previewLink"]

  connect() {
    super.connect();
    this.values = []
    this.element[this.identifier] = this

    if (this.containerTarget.classList.contains("table-scroll--fixed-column")) {
      if (this.containerTarget.getBoundingClientRect().bottom > window.innerHeight) {
        this.containerTarget.style.height = (window.innerHeight - this.containerTarget.getBoundingClientRect().top) + "px"
      }
    }

    if (this.previewLinkTargets.length > 0) {
      this.previewLinkTargets.forEach(function(elem) {
        elem.closest("tr").addEventListener("click", (e) => {
          if (e.srcElement.tagName != "A" && e.srcElement.tagName != "INPUT") {
            elem.click()
          }
        })
      });
    }

    if (this.containerTarget.classList.contains("table-scroll--fixed-column")) {
      this.containerTarget.style.overflowY = "scroll"
    }
  }

  check () {
    const actionsTarget = this.actionsTarget
    const countTarget = this.countTarget
    let params = new URLSearchParams();

    countTarget.innerHTML = this.checked.length + " selected";

    if (this.checked.length > 0) {
      actionsTarget.style.display = "flex"

    } else {
      actionsTarget.style.display = "none"
    }

    this.checked.forEach((element) => {
      element.closest("tr").classList.add("selected")
    });

    this.unchecked.forEach((element) => {
     element.closest("tr").classList.remove("selected")
    });
  }

  action (e) {
    e.preventDefault()
    var that = this;

    this.runAction(e)
  }

  deleteAction(e) {
    e.preventDefault()
    var confirmation = confirm("Are you sure you want to permanently delete " + this.checked.length + " items?");

    if (confirmation) {
      this.runAction(e)
    }
  }

  runAction (e) {
    var checked_values = this.checked.map(function (el) {
      return el.value
    })

    this.idInputTargets.forEach((el) => {
      el.value = JSON.stringify( checked_values )
    })

    e.target.closest('form').submit()
  }

}
